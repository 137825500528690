import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type TextFieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const TextField: React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  return (
    <div className='flex-col'>
      <input
        className='h-9 w-11/12 rounded-md border-2 border-gray p-1'
        value={value ?? ''}
        {...rest}
        ref={ref}
      ></input>

      {error && <span className='text-primary'>{error}</span>}
    </div>
  );
};

export default React.forwardRef(TextField);
