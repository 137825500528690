import React from 'react';

export type EditButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const EditButton = React.forwardRef((props: EditButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-lightBlue text-primary text-xs font-medium w-32 h-9 rounded-md ml-4 mr-2' ref={ref} {...props}>
    {props.children}
  </button>
));

EditButton.displayName = 'EditButton';

export default EditButton;
