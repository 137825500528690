import { string, object, SchemaOf, number, array, mixed } from 'yup';
import { FinishOrderRequest } from '../../../api/types/technician/FinishOrderRequest';
import { emptyToNull, commaToDot, emptyStringToUndefined } from 'src/utils/schemaUtils';
import { inputRequired, numberRequired } from 'src/validation/validationMessages';

export type ServiceFormTechnicianValues = FinishOrderRequest & {
  orderSignatureCustomer: Blob;
  orderSignatureTechnician: Blob;
};

const serviceFormTechnicianSchema: SchemaOf<ServiceFormTechnicianValues> = object().shape({
  materials: array().of(
    object().shape({
      price: number()
        .nullable(true)
        .defined()
        .default(null)
        .transform(emptyToNull)
        .typeError(numberRequired)
        .transform(commaToDot),
      material: string().nullable(true).defined().default(null),
    }),
  ),
  serviceMileage: number()
    .required(inputRequired)
    .transform(emptyStringToUndefined)
    .typeError(numberRequired)
    .transform(commaToDot),
  serviceTimeOnRoad: number()
    .required(inputRequired)
    .transform(emptyStringToUndefined)
    .typeError(numberRequired)
    .transform(commaToDot),
  serviceDuration: number().required(inputRequired).typeError(numberRequired).transform(commaToDot),
  serviceTotalPrice: number().nullable(true).defined().default(null).transform(emptyToNull),
  servicePaymentMethod: string().nullable(true).defined().default(null),
  status: string().defined().default(null),
  descriptionNote: string().nullable(true).defined().default(null),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderSignatureCustomer: mixed().required() as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderSignatureTechnician: mixed().required() as any,
});

export default serviceFormTechnicianSchema;
