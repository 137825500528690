import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ServiceFormValues } from './schema';

export const StatusWatcher: React.FC = () => {
  const { setValue, control } = useFormContext<ServiceFormValues>();
  const technicianId = useWatch({ name: 'technicianId', control });
  const datetime = useWatch({ name: 'datetime', control });

  useEffect(() => {
    if (datetime == null || technicianId == undefined || String(technicianId) === '') {
      setValue('status', 'NEW');
    } else {
      setValue('status', 'PLANNED');
    }
  }, [technicianId, datetime]);

  return null;
};
