import React, { ReactNode } from 'react';

type CardProps = {
  children?: ReactNode;
};

const Card: React.FC<CardProps> = ({ children }) => (
  <div className='w-96 h-full rounded-md shadow-md bg-white'>{children}</div>
);

export default Card;
