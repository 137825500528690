import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from '../../../components/EditButton';
import ServiceFormCard from '../ServiceFormCard';
import { useTechnicians } from 'src/store/technicianContext';
import { CreateOrderRequest } from 'src/api/types/orders/CreateOrderRequest';
import RowButton from 'src/components/RowButton';

interface PreviewServiceFormProps {
  data?: CreateOrderRequest & { id: number };
}

const PreviewServiceForm: React.FC<PreviewServiceFormProps> = ({ data }) => {
  const { orderId } = useParams();
  const technicians = useTechnicians();
  const navigate = useNavigate();

  const handleCloseForm = (): void => {
    navigate('/');
  };

  const date = data?.datetime && format(new Date(data?.datetime), 'dd.MM.yyyy HH:mm');

  return (
    <div className='flex-col w-2/4 tablet:w-full tablet:pl-3'>
      <div className='flex w-10/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-4 text-4xl text-text font-bold flex w-full'>
              Servis
              {data?.id ? ` #${data.id}` : ''}{' '}
            </div>
            {data?.id ? (
              <div className='flex flex-row items-center pt-4'>
                <EditButton onClick={() => handleCloseForm()}>Zpět</EditButton>
                <RowButton onClick={() => navigate(`/order/${orderId}/edit`)}>Upravit</RowButton>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <form className='w-10/12'>
        <ServiceFormCard>
          <div className='flex space-x-44 mb-2 mt-4'>
            <div className='font-bold text-text text-2xl ml-4'>Servis</div>
          </div>

          <div className='text-lightGrey text-sm ml-4'>Základní informace o servisu</div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-6'>Status</div>
            <div className='text-lightGrey'> {data?.status}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-3'>Technik</div>
            <div className='text-lightGrey'>
              {technicians.technicians.find((item) => item.id === data?.technicianId)?.name}
            </div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-1'>Datum</div>
            <div className='text-lightGrey'> {date}</div>
          </div>
          <HorizontalRow />
          <div className='font-bold text-text text-2xl ml-4'>Kontakt</div>

          <div className='text-lightGrey text-sm ml-4 mb-2'>Kontakt na osobu žádající o servis</div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mb-2'>Jméno kontaktní osoby</div>
            <div className='text-lightGrey'> {data?.contactPerson}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-10'>Název provozovny</div>
            <div className='text-lightGrey'> {data?.client}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-9'>Město</div>
            <div className='text-lightGrey'> {data?.city}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-11'>Ulice</div>
            <div className='text-lightGrey'> {data?.street}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-12'>PSČ</div>
            <div className='text-lightGrey'> {data?.zip}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-12'>IČO</div>
            <div className='text-lightGrey'> {data?.vat}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-8'>Email</div>
            <div className='text-lightGrey'> {data?.email}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-2'>Číslo zakázky</div>

            <div className='text-lightGrey'> {data?.invoiceNumber}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4'>Záruční oprava</div>
            <div className='text-lightGrey'> {data?.quarantee === true ? 'ano' : 'ne'}</div>
          </div>
          <HorizontalRow />
          <div className='font-bold text-text text-2xl ml-4'>Produkt</div>

          <div className='text-lightGrey text-sm ml-4'>Informace o produktu</div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4'>Výrobce</div>
            <div className='text-lightGrey'> {data?.productProducer}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4'>Model</div>
            <div className='text-lightGrey'> {data?.productModel}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-3'>Sériové číslo</div>
            <div className='text-lightGrey'> {data?.productSerialNumber}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-1 mb-10'>Popis</div>
            <div className='text-lightGrey'> {data?.productDescription}</div>
          </div>
        </ServiceFormCard>

        <div className='mt-5 grid place-items-end mr-20'>
          <div className='flex-row mr-4 mb-6'>
            <EditButton onClick={() => handleCloseForm()}>Zpět</EditButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PreviewServiceForm;
