import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type CashFieldProps = FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, number | null>;

const CashField: React.ForwardRefRenderFunction<HTMLInputElement, CashFieldProps> = (props, ref) => {
  const { value, ...rest } = props;
  return <input className='w-8/12 h-9 rounded-md border border-gray ' value={value ?? ''} {...rest} ref={ref}></input>;
};

export default React.forwardRef(CashField);
