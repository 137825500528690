import React from 'react';

export type RowButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const RowButton = React.forwardRef((props: RowButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-primary text-white text-xs font-medium w-32 h-9 rounded-md' ref={ref} {...props}>
    {props.children}
  </button>
));

RowButton.displayName = 'RowButton';

export default RowButton;
