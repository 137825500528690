import React from 'react';

export type TransparentButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const TransparentButton = React.forwardRef((props: TransparentButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button
    className='bg-background text-black text-xs font-medium h-9 rounded-md mr-4 border border-darkGrey w-5/6'
    ref={ref}
    {...props}
  >
    {props.children}
  </button>
));

TransparentButton.displayName = 'TransparentButton';

export default TransparentButton;
