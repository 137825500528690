import React, { useEffect, useState } from 'react';
import { OrderListItem } from 'src/api/types/orders/OrderListItemResponse';
import { useApiClient } from 'src/api/client';
import { useLocation } from 'react-router';

import ServiceItem from 'src/components/ServiceItem';

const ServiceCard: React.FC = () => {
  const Client = useApiClient();
  const { pathname } = useLocation();

  const [items, setItems] = useState<OrderListItem[]>([]);

  const getItemList = async (): Promise<void> => {
    const response = await Client.get('/orders');
    setItems(response.data);
  };

  useEffect(() => {
    getItemList();
  }, [pathname]);

  return (
    <>
      {items
        .sort((a, b) => b.id - a.id)
        .map((item, index) => {
          return (
            <ServiceItem key={item.id} item={item} getItemList={getItemList} isLastItem={items.length - 1 === index} />
          );
        })}
    </>
  );
};

export default ServiceCard;
