import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, Controller, SubmitErrorHandler, useFieldArray, FormProvider } from 'react-hook-form';
import { isNil } from 'ramda';
import { useApiClient } from 'src/api/client';
import { FinishOrderRequest } from '../../../api/types/technician/FinishOrderRequest';
import { PaymentMethod } from 'src/api/types/paymentMethods/PaymentMethodResponse';
import MobileTextfield from 'src/components/MobileTextfield';
import TechnicianFinishPageCard from 'src/components/TechnicianFinishPageCard';
import HorizontalRow from 'src/components/HorizontalRow';
import TechnicianEditButton from 'src/components/TechnicianEditButton';
import TechnicianRowButton from 'src/components/TechnicianRowButton';
import TransparentButton from 'src/components/TransparentButton';
import Modal from 'src/components/Modal';
import logo from 'src/pictures/logo.png';
import CashField from 'src/components/CashField';
import { yupResolver } from '@hookform/resolvers/yup';
import serviceFormTechnicianSchema, { ServiceFormTechnicianValues } from './technicianSchema';
import { useNavigateWithSearch } from 'src/utils/useNavigateWithSearch';
import Signature from '../../../components/Signature/index';
import { PriceWatcher } from './PriceWatcher';
import SelectMobile from 'src/components/SelectMobile';

interface TechnicianFinishFormProps {
  defaultValues?: Partial<ServiceFormTechnicianValues>;
}

const TechnicianOrderFinish: React.FC<TechnicianFinishFormProps> = () => {
  const navigate = useNavigateWithSearch();
  const Client = useApiClient();
  const [paymentMenthods, setPaymentMethods] = useState([]);
  const [isModalSucces, setIsModalSucces] = useState(false);

  const form = useForm<ServiceFormTechnicianValues>({
    resolver: yupResolver(serviceFormTechnicianSchema),
    defaultValues: {
      materials: [
        {
          material: '',
          price: undefined,
        },
        {
          material: '',
          price: undefined,
        },
        {
          material: '',
          price: undefined,
        },
      ],
      serviceDuration: 0,
      serviceMileage: 0,
      serviceTimeOnRoad: 0,
      descriptionNote: null,
      servicePaymentMethod: 'CASH',
      status: 'COMPLETED',
    },
  });

  const { handleSubmit, control } = form;

  const { fields, append } = useFieldArray({
    name: 'materials',
    control,
  });

  const onSubmit: SubmitHandler<ServiceFormTechnicianValues> = async ({
    orderSignatureCustomer,
    orderSignatureTechnician,
    ...data
  }) => {
    const modifiedData = {
      ...data,
      materials: data?.materials.filter(({ material, price }) => material !== '' && !isNil(material) && !isNil(price)),
    };
    try {
      const files = new FormData();
      files.append('orderSignatureTechnician', orderSignatureTechnician);
      files.append('orderSignatureCustomer', orderSignatureCustomer);
      const test = await Client.post('/technician-order/signature', files);

      if (test.status === 201) {
        const response = await Client.put('/technician-order', modifiedData);
        if (response.status === 200) {
          setIsModalSucces(true);
        }
      }
    } catch (error) {
      console.error('test', error);
    }
  };

  const onError: SubmitErrorHandler<FinishOrderRequest> = (errors) => {
    console.log(errors);
  };

  const getPaymentMethod = async (): Promise<void> => {
    const response = await Client.get('payment-method');
    setPaymentMethods(response.data);
  };

  useEffect(() => {
    getPaymentMethod();
  }, []);

  return (
    <div className='bg-background grid place-items-center'>
      <div className='flex mb-4'>
        <img src={logo} className='App-logo px-8 pt-6 w-40' alt='logo' />
      </div>
      <div className='grid place-items-center'>
        <FormProvider {...form}>
          <form className='grid place-items-center' onSubmit={handleSubmit(onSubmit, onError)}>
            <PriceWatcher />
            <TechnicianFinishPageCard>
              <div className='flex space-x-44 mb-2 mt-4'>
                <div className='font-bold text-text text-2xl ml-4'>Dokončení servisu</div>
              </div>
              <div className='text-lightGrey text-sm ml-4'>
                Dodatečné informace, které se doplňují poté co je servis proveden.
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2'>Práce</div>
                <div className='grid grid-row-2'>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceDuration'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />

                    <div className='p-2'> hodin</div>
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4'>
                <div className='font-bold text-text ml-2 mr-3'>Materiál</div>
                <div className='grid'>
                  <div className='grid grid-cols-2 '>
                    <div>Materiál</div> <div>Cena</div>
                  </div>

                  {fields.map((field, index) => {
                    return (
                      <div className='grid grid-cols-2 mb-2' key={field.id}>
                        <Controller
                          control={control}
                          name={`materials.${index}.material`}
                          render={({ field }) => <MobileTextfield {...field} />}
                        />
                        <Controller
                          control={control}
                          name={`materials.${index}.price`}
                          render={({ field }) => <CashField {...field} />}
                        />
                      </div>
                    );
                  })}

                  <TransparentButton type='button' onClick={() => append({ material: '', price: undefined })}>
                    Nový materiál
                  </TransparentButton>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 items-center'>
                <div className='font-bold text-text ml-2 mr-3 mb-2'>Doprava</div>
                <div className='grid grid-row-2'>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceMileage'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />
                    <div className='p-2 ml-5'> km</div>
                  </div>
                  <div className='mt-2 flex'>
                    <Controller
                      control={control}
                      name='serviceTimeOnRoad'
                      render={({ field, fieldState }) => (
                        <>
                          <MobileTextfield {...field} error={fieldState.error?.message} />
                        </>
                      )}
                    />
                    <div className='p-2'> hodin</div>
                  </div>
                </div>
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4'>
                <div className='font-bold text-text ml-2 mr-3'>Finální cena</div>
                <Controller
                  control={control}
                  name='serviceTotalPrice'
                  render={({ field: { value } }) => <> {value && !isNaN(value) ? value : 0} </>}
                />
                Kč
              </div>
              <HorizontalRow />
              <div className='grid grid-cols-2 mb-4 mr-2 items-center'>
                <div className='font-bold text-text ml-2 mr-3'>Metoda zaplacení</div>

                <Controller
                  control={control}
                  name='servicePaymentMethod'
                  render={({ field }) => (
                    <SelectMobile {...field}>
                      {paymentMenthods.map((paymentMenthod: PaymentMethod) => (
                        <option key={paymentMenthod.id} value={paymentMenthod.name}>
                          {paymentMenthod.label}
                        </option>
                      ))}
                    </SelectMobile>
                  )}
                />
              </div>
              <HorizontalRow />
              <div className='tablet:grid tablet:grid-cols-2 mb-4 mr-2 items-center desktop:grid desktop:grid-cols-2'>
                <div className='font-bold text-text ml-2 mr-3'>Podpis technika</div>
                <div className='border w-96 border-border ml-4'>
                  <Controller
                    control={control}
                    name='orderSignatureCustomer'
                    render={({ field }) => <Signature handleSign={field.onChange} />}
                  />{' '}
                </div>
              </div>
              <HorizontalRow />
              <div className='mb-4 mr-2 items-center tablet:grid tablet:grid-cols-2 desktop:grid desktop:grid-cols-2'>
                <div className='font-bold text-text ml-2 mr-3'>Podpis zákazník</div>
                <div className='border w-96 border-border mb-2 ml-4'>
                  <Controller
                    control={control}
                    name='orderSignatureTechnician'
                    render={({ field }) => <Signature handleSign={field.onChange} />}
                  />
                </div>
              </div>
            </TechnicianFinishPageCard>
            <div className='mt-5 grid-cols-2 place-items-center'>
              <div className='flex mb-6 ml-2'>
                <TechnicianEditButton onClick={() => navigate({ pathname: '/technician-order' })}>
                  Zpět
                </TechnicianEditButton>
                <TechnicianRowButton type='submit'>Dokončit</TechnicianRowButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <Modal isOpen={isModalSucces} hideButtons>
        <div>Servis byl dokončen.</div>
      </Modal>
    </div>
  );
};

export default TechnicianOrderFinish;
