import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ServiceFormTechnicianValues } from './technicianSchema';

export const PriceWatcher: React.FC = () => {
  const { setValue, control } = useFormContext<ServiceFormTechnicianValues>();
  const serviceDuration = useWatch({ name: 'serviceDuration', control }) as unknown as string;
  const serviceMileage = useWatch({ name: 'serviceMileage', control }) as unknown as string;
  const serviceTimeOnRoad = useWatch({ name: 'serviceTimeOnRoad', control }) as unknown as string;
  const materials = useWatch({ name: 'materials', control });
  const materialsSum = materials.reduce(
    (prev, current) => (current?.price ? prev + parseFloat(current.price.toString().replace(',', '.')) : prev),
    0,
  );

  const finalPrice =
    (serviceDuration !== '' ? parseFloat(serviceDuration.toString().replace(',', '.')) : 0) * 750 +
    (serviceMileage !== '' ? parseFloat(serviceMileage.toString().replace(',', '.')) : 0) * 14 +
    (serviceTimeOnRoad !== '' ? parseFloat(serviceTimeOnRoad.toString().replace(',', '.')) : 0) * 290 +
    materialsSum;

  useEffect(() => {
    setValue('serviceTotalPrice', finalPrice);
  }, [finalPrice]);

  return null;
};
