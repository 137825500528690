import { yupResolver } from '@hookform/resolvers/yup';
import { format, isValid, parse, parseISO } from 'date-fns';
import React from 'react';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useApiClient } from 'src/api/client';
import DatePicker from 'src/components/DatePicker';
import HorizontalRow from 'src/components/HorizontalRow';
import Select from 'src/components/Select';
import TextField from 'src/components/Textfield';
import DescriptionField from 'src/components/DescriptionField';
import TimePicker from 'src/components/TimePicker';
import { useTechnicians } from 'src/store/technicianContext';
import { useStatuses } from 'src/store/statusContext';
import EditButton from '../../../components/EditButton';
import RowButton from '../../../components/RowButton';
import ServiceFormCard from '../ServiceFormCard';
import serviceFormSchema, { ServiceFormValues } from './schema';
import { StatusWatcher } from './StatusWatcher';

interface ServiceFormProps {
  defaultValues?: Partial<ServiceFormValues>;
  serviceNumber?: number;
}
const ServiceForm: React.FC<ServiceFormProps> = ({ defaultValues, serviceNumber }) => {
  const Client = useApiClient();
  const { technicians } = useTechnicians();
  const { findLabelByName } = useStatuses();
  const navigate = useNavigate();

  const handleCloseForm = (): void => {
    navigate('/');
  };

  const form = useForm<ServiceFormValues>({
    resolver: yupResolver(serviceFormSchema),
    defaultValues: defaultValues || {
      quarantee: true,
      status: 'NEW',
    },
  });

  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<ServiceFormValues> = async (data) => {
    try {
      if (serviceNumber) {
        const response = await Client.put(`/orders/${serviceNumber}`, data);
        if (response.status === 200) {
          navigate('/');
        }
      } else {
        const response = await Client.post('/orders', data);
        if (response.status === 201) {
          navigate('/');
        }
      }
    } catch (error) {
      console.error('test', error);
    }
  };

  const onError: SubmitErrorHandler<ServiceFormValues> = (errors) => {
    console.log(errors);
  };

  return (
    <div className='flex-col w-2/4 tablet:w-full tablet:pl-3'>
      <FormProvider {...form}>
        <form className='w-10/12' onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='flex-row flex justify-between'>
            <div className='mt-4 text-4xl text-text font-bold mb-4 flex flex-row'>
              {serviceNumber ? `Servis #${serviceNumber}` : 'Nový servis'}
            </div>
            {serviceNumber ? (
              <div className='flex flex-row items-center'>
                <EditButton onClick={handleCloseForm}>Zavřít</EditButton>
                <RowButton type='submit'>Uložit změny</RowButton>
              </div>
            ) : (
              ''
            )}
          </div>

          <StatusWatcher />
          <ServiceFormCard>
            <div className='flex space-x-44 mb-2 mt-4'>
              <div className='font-bold text-text text-2xl ml-4'>Servis</div>
            </div>

            <div className='text-lightGrey text-sm ml-4'>Základní informace o servisu</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-6'>Status</div>
              <Controller
                control={control}
                name='status'
                render={({ field }) => <>{findLabelByName(field?.value)}</>}
              />{' '}
            </div>

            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-3'>Technik</div>
              <Controller
                control={control}
                name='technicianId'
                render={({ field }) => (
                  <>
                    <Select {...field}>
                      <option value=''></option>
                      {technicians.map((technician) => (
                        <option key={technician.id} value={technician?.id}>
                          {technician.name}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <Controller
              control={control}
              name='datetime'
              render={({ field }) => {
                const value = isValid(parse(field.value ?? '', 'dd.MM.yyyy', new Date()))
                  ? format(parse(field.value ?? '', 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
                  : field.value;
                return (
                  <>
                    <div className='grid grid-cols-2 mb-4'>
                      <div className='font-bold text-text ml-4 mr-1'>Datum</div>
                      <DatePicker
                        {...field}
                        onChange={(date) => {
                          if (!date) {
                            field.onChange(null);
                            return;
                          }

                          field.onChange(date.toISOString());
                        }}
                        value={value ? parseISO(value) : null}
                      />
                    </div>
                    <HorizontalRow />
                    <div className='grid grid-cols-2 mb-4'>
                      <div className='font-bold text-text ml-4 mr-1'>Čas</div>
                      <TimePicker
                        {...field}
                        value={value ? parseISO(value) : null}
                        onChange={(date) => {
                          if (!date) {
                            field.onChange(null);
                            return;
                          }

                          field.onChange(date.toISOString());
                        }}
                      />
                    </div>
                  </>
                );
              }}
            />
            <HorizontalRow />

            <div className='font-bold text-text text-2xl ml-4'>Kontakt</div>
            <div className='text-lightGrey text-sm ml-4 mb-2'>Kontakt na osobu žádající o servis</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mb-2'>Jméno kontaktní osoby*</div>
              <Controller
                control={control}
                name='contactPerson'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-10'>Název provozovny*</div>
              <Controller
                control={control}
                name='client'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-9'>Město*</div>
              <Controller
                control={control}
                name='city'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-11'>Ulice*</div>
              <Controller
                control={control}
                name='street'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-12'>PSČ*</div>
              <Controller
                control={control}
                name='zip'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-12'>IČO</div>
              <Controller
                control={control}
                name='vat'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-8'>Email*</div>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-2'>Číslo zakázky</div>

              <Controller
                control={control}
                name='invoiceNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />

            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4'>Záruční oprava*</div>

              <Controller
                control={control}
                name='quarantee'
                render={({ field }) => {
                  const value = field?.value === true ? 'ano' : 'ne';
                  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
                    field.onChange(e.target.value === 'ano');
                  };
                  return (
                    <>
                      <Select {...field} value={value} onChange={onChange}>
                        <option value={'ano'}>ANO</option>
                        <option value={'ne'}>NE</option>)
                      </Select>
                    </>
                  );
                }}
              />
            </div>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4'>Produkt</div>

            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o produktu</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4'>Výrobce*</div>
              <Controller
                control={control}
                name='productProducer'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4'>Model*</div>
              <Controller
                control={control}
                name='productModel'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-3'>Sériové číslo*</div>
              <Controller
                control={control}
                name='productSerialNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-1 mb-10'>Popis</div>
              <Controller
                control={control}
                name='productDescription'
                render={({ field, fieldState }) => (
                  <>
                    <DescriptionField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
          </ServiceFormCard>

          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => handleCloseForm()}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ServiceForm;
