import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

interface AuthContextProps {
  token: string;
  setAuthToken: (value: string, canSaveToken?: boolean) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultState: AuthContextProps = {
  token: '',
  setAuthToken: () => {
    return;
  },
};

export const AuthContext = createContext<AuthContextProps>(defaultState);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState(Cookies.get('token') || '');
  const handleSetToken = (tokenHandle: string, canSaveToken?: boolean): void => {
    setToken(tokenHandle);
    if (canSaveToken) {
      Cookies.set('token', tokenHandle, { expires: 365, path: '/' });
    } else {
      Cookies.set('token', tokenHandle, { path: '/' });
    }
  };

  return <AuthContext.Provider value={{ token, setAuthToken: handleSetToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);
