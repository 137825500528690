import React from 'react';
import EditButton from '../EditButton';
import RowButton from '../RowButton';

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  children: React.ReactNode;
  hideButtons?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, onClose, onConfirm, children, hideButtons } = props;
  return (
    <>
      {isOpen ? (
        <>
          <div className='flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50'>
            <div className='relative my-6 mx-auto max-w-3xl border border-black rounded-lg'>
              <div className='rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4'>
                {children}

                <div className='flex justify-center mb-2 mt-4 ml-4 mr-4'>
                  {!hideButtons && (
                    <>
                      {' '}
                      <EditButton type='button' onClick={() => onClose?.()}>
                        Storno
                      </EditButton>
                      <RowButton type='button' onClick={() => onConfirm && onConfirm()}>
                        Dokončit
                      </RowButton>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
