import React, { ReactNode } from 'react';

type TechnicianFinishPageCardProps = {
  children?: ReactNode;
};

const TechnicianFinishPageCard: React.FC<TechnicianFinishPageCardProps> = ({ children }) => (
  <div className='rounded-md shadow-md bg-white border border-border'>{children}</div>
);

export default TechnicianFinishPageCard;
