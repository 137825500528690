import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type DescriptionFieldProps = FieldProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement,
  string | number | null
>;

const DescriptionField: React.ForwardRefRenderFunction<HTMLTextAreaElement, DescriptionFieldProps> = (props, ref) => {
  const { value, ...rest } = props;
  return (
    <textarea
      className='w-11/12 h-20 rounded-md border-2 border-gray p-1'
      value={value ?? ''}
      {...rest}
      ref={ref}
    ></textarea>
  );
};

export default React.forwardRef(DescriptionField);
