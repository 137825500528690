import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import ProtectedRoute from './components/ProtectedRoute/index';
import TechnicianOrder from './pages/TechnicianOrder/TechnicianOrder';
import { AuthProvider } from './store/authContext';

import TechnicianOrderFinish from './pages/TechnicianOrder/TechnicianOrderFinish';
import { TechnicianAuthProvider } from './store/technicianAuthContext';

const App: React.FC = () => {
  return (
    <div className='bg-background w-screen'>
      <Routes>
        <Route
          path='/technician-order/*'
          element={
            <TechnicianAuthProvider>
              <Routes>
                <Route path='/' element={<TechnicianOrder />} />
                <Route path='/finish' element={<TechnicianOrderFinish />} />
              </Routes>
            </TechnicianAuthProvider>
          }
        />
        <Route
          path='/*'
          element={
            <AuthProvider>
              <Routes>
                <Route
                  path='/*'
                  element={
                    <ProtectedRoute>
                      <HomePage />
                    </ProtectedRoute>
                  }
                />

                <Route path='/login' element={<LoginPage />} />
                <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              </Routes>
            </AuthProvider>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
