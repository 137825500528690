import React from 'react';

const ServiceListIcon: React.FC = () => (
  <svg className='w-9 h-9 ml-9 fill-white' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 2a1 1 0 000 2h2a1 1 0 100-2H9z'></path>
    <path
      fillRule='evenodd'
      d='M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z'
      clipRule='evenodd'
    ></path>
  </svg>
);

export default ServiceListIcon;
