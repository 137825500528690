import { format, parse } from 'date-fns';
import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type TimePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  onChange?: (date: Date | null) => void;
};

const TimePicker: React.ForwardRefRenderFunction<HTMLInputElement, TimePickerProps> = (props, ref) => {
  const { value, onChange, ...rest } = props;

  return (
    <input
      type='time'
      className='w-11/12 h-9 rounded-md border-2 border-gray'
      value={value ? format(value, 'HH:mm') : ''}
      onChange={(e) => {
        const time = e.target.value;

        if (!time) {
          onChange?.(null);
          return;
        }

        const parsedTime = parse(time, 'HH:mm', value ?? new Date());
        onChange?.(parsedTime);
      }}
      {...rest}
      ref={ref}
    ></input>
  );
};

export default React.forwardRef(TimePicker);
