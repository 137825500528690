import { format, parse } from 'date-fns';
import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type DatePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  onChange?: (date: Date | null) => void;
};

const DatePicker: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = (props, ref) => {
  const { value, onChange, ...rest } = props;

  return (
    <input
      type='date'
      className='w-11/12 h-9 rounded-md border-2 border-gray'
      value={value ? format(value, 'yyyy-MM-dd') : ''}
      onChange={(e) => {
        const date = e.target.value;

        if (!date) {
          onChange?.(null);
          return;
        }

        const prevDate = value ?? new Date();

        const parsedDate = parse(date, 'yyyy-MM-dd', prevDate);
        parsedDate.setHours(
          prevDate.getHours(),
          prevDate.getMinutes(),
          prevDate.getSeconds(),
          prevDate.getMilliseconds(),
        );

        onChange?.(parsedDate);
        return;
      }}
      {...rest}
      ref={ref}
    ></input>
  );
};

export default React.forwardRef(DatePicker);
