import React from 'react';

export type TechnicianRowButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const TechnicianRowButton = React.forwardRef((props: TechnicianRowButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-primary text-white text-xs font-medium w-48 h-9 rounded-md mr-3' ref={ref} {...props}>
    {props.children}
  </button>
));

TechnicianRowButton.displayName = 'TechnicianRowButton';

export default TechnicianRowButton;
