import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface SignatureProps {
  handleSign: (sign: Blob | undefined) => void;
}

const Signature: React.FC<SignatureProps> = ({ handleSign }) => {
  const sigPad = useRef<SignatureCanvas | null>();

  const getSignatureRef = (element: SignatureCanvas | null): void => {
    sigPad.current = element;
  };

  const handleTrim = (): void => {
    sigPad.current?.getTrimmedCanvas().toBlob((blob) => {
      if (blob) {
        handleSign(blob);
      }
    });
  };

  return (
    <>
      <div>
        <SignatureCanvas
          penColor='black'
          ref={(ref) => getSignatureRef(ref)}
          canvasProps={{ width: 384, height: 200 }}
          onEnd={handleTrim}
        />
      </div>
    </>
  );
};

export default Signature;
