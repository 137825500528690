import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useApiClient } from 'src/api/client';
import { CreateOrderRequest } from 'src/api/types/orders/CreateOrderRequest';
import PreviewServiceForm from 'src/pages/HomePage/PreviewServiceForm';
import ServiceForm from 'src/pages/HomePage/ServiceForm';

const ServiceFetch: React.FC = () => {
  const { orderId } = useParams();
  const Client = useApiClient();
  const [dataInForm, setDataInForm] = useState<CreateOrderRequest & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (orderId) {
      const response = await Client.get(`/orders/${orderId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [orderId]);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<PreviewServiceForm data={dataInForm} />} />
        <Route
          path='/edit'
          element={dataInForm ? <ServiceForm serviceNumber={dataInForm.id} defaultValues={dataInForm} /> : null}
        />
      </Routes>
    </>
  );
};

export default ServiceFetch;
