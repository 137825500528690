import React from 'react';

export type CheckboxProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'type'>;

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (props, ref) => (
  <input
    type='checkbox'
    className='w-5 h-5 rounded-md shadow-md border-2 border-gray appearance-none checked:bg-primary'
    {...props}
    ref={ref}
  ></input>
);

export default React.forwardRef(Checkbox);
