import React from 'react';
import { Route, useLocation, useNavigate, Outlet, Routes } from 'react-router-dom';
import SidePanel from '../../components/SidePanel';
import ServiceCard from './ServiceCard';
import ServiceHeader from './ServiceHeader';
import ServiceForm from './ServiceForm';
import { SidePanelRoutes } from '../../components/SidePanel';
import RowButton from '../../components/RowButton';
import ServiceFetch from 'src/components/ServiceFetch';
import { TechnicianProvider } from 'src/store/technicianContext';
import { StatusProvider } from 'src/store/statusContext';

const HomePage: React.FC = () => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <div className='flex '>
        <SidePanel activeRoute={SidePanelRoutes.SERVICE_LIST} />
        <div className='flex desktop:w-full tablet:flex-col tablet:w-full'>
          <div className='flex-col w-2/4 tablet:w-full'>
            <div className='flex justify-between'>
              <div className='ml-6 mt-4 text-4xl text-text font-bold tablet:ml-10'>Seznam servisů</div>
              <div className='mt-4 mr-40'>
                {pathname === '/' && (
                  <RowButton
                    onClick={() => {
                      navigate('/order/new');
                    }}
                  >
                    Nový servis
                  </RowButton>
                )}
              </div>
            </div>
            <div className='desktop:ml-6 desktop:mt-4 tablet:w-full tablet:p-3'>
              <div className='w-10/12 '>
                <ServiceHeader />
              </div>

              <div className='w-10/12 h-[31.25rem] overflow-y-scroll rounded-b-lg '>
                <ServiceCard />
              </div>
            </div>
          </div>

          <Outlet />
          <TechnicianProvider>
            <StatusProvider>
              <Routes>
                <Route path='/order/:orderId/*' element={<ServiceFetch />} />
                <Route path='/order/new' element={<ServiceForm defaultValues={{ quarantee: true, status: 'NEW' }} />} />
              </Routes>
            </StatusProvider>
          </TechnicianProvider>
        </div>
      </div>
    </>
  );
};

export default HomePage;
