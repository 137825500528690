import { isNil } from 'ramda';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useApiClient } from 'src/api/client';
import { OrderListItem } from 'src/api/types/orders/OrderListItemResponse';
import InvoicingIcon from 'src/components/Icons/InvoicingIcon';
import Modal from 'src/components/Modal';

interface ServiceItemProps {
  item: OrderListItem;
  getItemList: () => void;
  isLastItem?: boolean;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ item, getItemList, isLastItem }) => {
  const Client = useApiClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const date = isNil(item.datetime) ? '' : new Date(item.datetime).toLocaleDateString();

  const onHandleCompletedService = async (id: string): Promise<void> => {
    try {
      const response = await Client.put(`/orders/${id}/status`, {
        status: 'INVOICED',
      });

      if (response.status === 200) {
        getItemList();
      }
    } catch (error) {
      console.error('test', error);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/order/${item.id}/preview`);
        }}
        key={item.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof item.id === 'number' && pathname.includes(item.id.toString()) ? 'bg-lightBlue' : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg border-b' : ''
          }`}
        >
          {item.id}
        </div>
        <div className='text-text cursor-pointer text-left w-full border-b border-border pt-3'>
          {item.technician?.name}
        </div>
        <div className='text-text cursor-pointer text-left w-full border-b border-border pt-3 '>{date}</div>
        <div className='text-text cursor-pointer text-left w-full border-b border-border pt-3 '>
          {item.status.label}
        </div>
        <div
          className={`text-text cursor-pointer pt-3 flex justify-center  h-12 w-1/5 pr-3 border-b border-r border-border pt-3 ${
            isLastItem ? 'rounded-br-lg border-b' : ''
          } `}
        >
          {item.status.name === 'COMPLETED' ? (
            <span
              onClick={(e) => {
                setIsModalOpen(true);
                e.stopPropagation();
              }}
            >
              <InvoicingIcon />
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => onHandleCompletedService(item.id.toString())}
      >
        Opravdu chcete objednávky vyfakturovat?
      </Modal>
    </div>
  );
};

export default ServiceItem;
