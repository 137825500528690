import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { useMemo } from 'react';
import { useTechnicianAuth } from 'src/store/technicianAuthContext';
import { useAuth } from '../store/authContext';

export interface UseClientOptions {
  excludeToken?: boolean;
}

export const useApiClient = (options?: UseClientOptions): AxiosInstance => {
  const { token } = useAuth();
  const { token: techToken } = useTechnicianAuth();

  return useMemo(() => {
    const headers: AxiosRequestHeaders = {};

    if (!options?.excludeToken) {
      headers.authorization = `Bearer ${techToken || token}`;
    }

    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL || '/api',
      headers: headers,
    });

    return client;
  }, [token, techToken]);
};
