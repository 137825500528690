import React, { ReactNode } from 'react';

type TechnicianPageCardProps = {
  children?: ReactNode;
};

const TechnicianPageCard: React.FC<TechnicianPageCardProps> = ({ children }) => (
  <div className='w-10/12 h-auto rounded-md shadow-md bg-white border border-border '>{children}</div>
);

export default TechnicianPageCard;
