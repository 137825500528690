import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Status } from 'src/api/types/status/StatusResponse';
import { useApiClient } from 'src/api/client';

interface StatusContextProps {
  statuses: Status[];
  setStatuses: React.Dispatch<React.SetStateAction<Status[]>>;
  findLabelByName: (name: string) => string;
}

const defaultState: StatusContextProps = {
  statuses: [],
  setStatuses: () => {
    return;
  },
  findLabelByName: () => {
    return '';
  },
};

interface statusContextProviderProps {
  children: React.ReactNode;
}

export const StatusContext = createContext<StatusContextProps>(defaultState);

export const StatusProvider: React.FC<statusContextProviderProps> = ({ children }) => {
  const Client = useApiClient();
  const [statuses, setStatuses] = useState<Status[]>([]);

  const getStatuses = async (): Promise<void> => {
    const response = await Client.get('/status');

    setStatuses(response.data);
  };

  const findLabelByName = useCallback(
    (name: string): string => {
      console.log(statuses.find((status) => status.name === name));
      return statuses.find((status) => status.name === name)?.label || name;
    },
    [statuses],
  );

  useEffect(() => {
    getStatuses();
  }, []);

  return <StatusContext.Provider value={{ statuses, setStatuses, findLabelByName }}>{children}</StatusContext.Provider>;
};

export const useStatuses = (): StatusContextProps => useContext(StatusContext);
