import React from 'react';

import { FieldProps } from 'src/types/commonTypes';
export type SelectProps = FieldProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement,
  string | number | null
>;

const Select = React.forwardRef((props: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
  const { value, ...rest } = props;
  return (
    <select
      className=' text-black w-11/12 h-9 rounded-md border-2 border-gray '
      value={value ?? ''}
      ref={ref}
      {...rest}
    >
      {props.children}
    </select>
  );
});

Select.displayName = 'Select';

export default Select;
