import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../pictures/logo.png';
import LogOutIcon from '../Icons/LogOutIcon';
import ServiceListIcon from '../Icons/ServiceListIcon';
import Cookies from 'js-cookie';

interface SidePanelPropTypes {
  activeRoute?: SidePanelRoutes;
}

export enum SidePanelRoutes {
  SERVICE_LIST = 'serviceList',
}

const SidePanel: React.FC<SidePanelPropTypes> = ({ activeRoute }) => {
  const navigate = useNavigate();

  return (
    <div className='bg-primary w-44 min-h-screen content-between grid place-items-center'>
      <div className='flex-col grid place-items-center'>
        <img src={logo} className='App-logo px-8 pt-6 w-72' alt='logo' />
        <div>
          <div
            className={`text-white mb-8 mt-10 p-4 ${
              activeRoute === SidePanelRoutes.SERVICE_LIST ? 'rounded-lg bg-darkBlue' : ''
            } `}
          >
            <Link to='/'>
              <ServiceListIcon />
              Seznam servisů
            </Link>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          Cookies.remove('token');
          navigate('/login');
        }}
      >
        <div className='text-white mb-8'>
          <LogOutIcon />
          Odhlásit
        </div>
      </button>
    </div>
  );
};

export default SidePanel;
