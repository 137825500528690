import React from 'react';
import CheckIcon from '../../../components/Icons/CheckIcon';

const ServiceHeader: React.FC = () => (
  <div className='flex'>
    <div className='border-l border-t border-b border-border w-full text-center  bg-white h-10 rounded-tl-lg pt-2'>
      ID
    </div>
    <div className='border-t border-b border-border w-full text-left bg-white  h-10 pt-2'>Technik</div>
    <div className='border-t border-b  border-border w-full text-left bg-white  h-10 pt-2'>Datum</div>
    <div className='border-t border-b border-border w-full text-left bg-white  h-10 pt-2'>Status</div>
    <div className='border-r border-t border-b border-border text-center bg-white h-10 rounded-tr-lg pt-2 flex justify-center pr-3'>
      <CheckIcon />
    </div>
  </div>
);

export default ServiceHeader;
