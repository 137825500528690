import React, { useState, useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TechnicianOrderResponse } from '../../../api/types/technician/TechnicianOrderResponse';
import { useApiClient } from 'src/api/client';
import { format } from 'date-fns';
import TechnicianPageCard from 'src/components/TechnicianPageCard';
import HorizontalRow from 'src/components/HorizontalRow';
import TechnicianEditButton from 'src/components/TechnicianEditButton';
import TechnicianRowButton from 'src/components/TechnicianRowButton';
import logo from 'src/pictures/logo.png';
import Modal from 'src/components/Modal';
import { useNavigateWithSearch } from 'src/utils/useNavigateWithSearch';
import DescriptionField from 'src/components/DescriptionField';
import { UnsuccessfulOrderRequest } from 'src/api/types/technician/UnsuccesfulOrderRequest';
import EditButton from 'src/components/EditButton';
import RowButton from 'src/components/RowButton';

interface TechnicianFinishFormProps {
  defaultValues?: Partial<UnsuccessfulOrderRequest>;
}

const TechnicianOrder: React.FC<TechnicianFinishFormProps> = () => {
  const navigate = useNavigateWithSearch();
  const Client = useApiClient();
  const [dataInForm, setDataInForm] = useState<TechnicianOrderResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSucces, setIsModalSucces] = useState(false);

  const onHandleUnsuccessfulService: SubmitHandler<UnsuccessfulOrderRequest> = async (data) => {
    try {
      const response = await Client.put('/technician-order/unsuccessful', {
        status: 'UNSUCCESSFUL',
        descriptionNote: data.descriptionNote,
      });

      if (response.status === 200) {
        setIsModalSucces(true);
      }
    } catch (error) {
      console.error('test', error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const form = useForm<UnsuccessfulOrderRequest>({
    defaultValues: {
      status: 'UNSUCCESSFUL',
      descriptionNote: null,
    },
  });

  const { handleSubmit, control } = form;

  const getDataToForm = async (): Promise<void> => {
    {
      const response = await Client.get('/technician-order');
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, []);

  const date = dataInForm?.datetime && format(new Date(dataInForm?.datetime), 'dd.MM.yyyy HH:mm');

  return (
    <div className='bg-background '>
      <div className='grid place-items-center'>
        <form className='min-w-full grid place-items-center'>
          <div className='w-10/12'>
            <div className='flex justify-start mb-4'>
              <div className='ml-2 mt-4 text-4xl text-text font-bold'>Servis číslo #{dataInForm?.id} </div>
              <img src={logo} className='App-logo px-8 pt-6 w-44 mb-2' alt='logo' />
            </div>
          </div>

          <TechnicianPageCard>
            {' '}
            <div className='flex space-x-44 mb-2 mt-4'>
              <div className='font-bold text-text text-2xl ml-4'>Servis</div>
            </div>
            <div className='text-lightGrey text-sm ml-4'>Základní informace o servisu</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Technik</div>
              <div className='text-lightGrey'>{dataInForm?.technician}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Datum</div>
              <div className='text-lightGrey'>{date}</div>
            </div>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4 mb-2'>Kontakt</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Kontakt na osobu žádající o servis</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Jméno kontaktní osoby</div>
              <div className='text-lightGrey'> {dataInForm?.contactPerson}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Název místa</div>
              <div className='text-lightGrey'> {dataInForm?.client}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Město</div>
              <div className='text-lightGrey'> {dataInForm?.city}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Ulice</div>
              <div className='text-lightGrey'> {dataInForm?.street}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>PSČ</div>
              <div className='text-lightGrey'> {dataInForm?.zip}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>IČO</div>
              <div className='text-lightGrey'> {dataInForm?.vat}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>E-mail</div>
              <div className='text-lightGrey'> {dataInForm?.email}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Číslo zakázky</div>
              <div className='text-lightGrey'> {dataInForm?.invoiceNumber}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Záruční oprava</div>
              <div className='text-lightGrey'>{dataInForm?.quarantee === true ? 'ano' : 'ne'}</div>
            </div>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4'>Produkt</div>
            <div className='text-lightGrey text-sm ml-4'>Informace o produktu</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Výrobce</div>
              <div className='text-lightGrey'>{dataInForm?.productProducer}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Model</div>
              <div className='text-lightGrey'> {dataInForm?.productModel}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-10 mr-3'>Sériové číslo</div>
              <div className='text-lightGrey'> {dataInForm?.productSerialNumber}</div>
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-10'>
              <div className='font-bold text-text ml-10 mr-3'>Popis</div>
              <div className='text-lightGrey mr-10'>{dataInForm?.productDescription}</div>
            </div>
          </TechnicianPageCard>

          <div className='mt-5 grid place-items-center'>
            <div className='flex mb-6 ml-2'>
              <TechnicianEditButton type='button' onClick={() => setIsModalOpen(true)}>
                Neúspěšný servis
              </TechnicianEditButton>
              <TechnicianRowButton onClick={() => navigate({ pathname: `/technician-order/finish` })}>
                Další
              </TechnicianRowButton>
            </div>
          </div>
        </form>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onHandleUnsuccessfulService)}>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} hideButtons>
            <div className='relative p-6 flex-auto'>Opravdu chcete servis označit za nedokončený?</div>
            <div className='flex-col ml-8'>
              Poznámka pro neúspěšný servis
              <Controller
                control={control}
                name='descriptionNote'
                render={({ field }) => <DescriptionField {...field} />}
              />
              <EditButton type='button' onClick={() => setIsModalOpen(false)}>
                Storno
              </EditButton>
              <RowButton type='submit'>Dokončit</RowButton>
            </div>
          </Modal>
        </form>
      </FormProvider>
      <Modal isOpen={isModalSucces} hideButtons>
        <div>Servis byl označen za neúspěšný.</div>{' '}
      </Modal>
    </div>
  );
};

export default TechnicianOrder;
