import React from 'react';

export type TechnicianEditButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const TechnicianEditButton = React.forwardRef((props: TechnicianEditButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-lightBlue text-primar text-xs font-medium w-48 h-9 rounded-md mr-4' ref={ref} {...props}>
    {props.children}
  </button>
));

TechnicianEditButton.displayName = 'TechnicianEditButton';

export default TechnicianEditButton;
