import { string, object, SchemaOf, bool, number } from 'yup';
import { CreateOrderRequest } from 'src/api/types/orders/CreateOrderRequest';
import { emptyToNull } from 'src/utils/schemaUtils';
import { inputRequired } from '../../../validation/validationMessages';

export type ServiceFormValues = CreateOrderRequest;

const serviceFormSchema: SchemaOf<ServiceFormValues> = object().shape({
  client: string().required(inputRequired),
  status: string().defined().default(null).transform(emptyToNull),
  technicianId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  servicePaymentMethod: string().nullable(true).defined().default(null).transform(emptyToNull),
  street: string().required(inputRequired),
  city: string().required(inputRequired),
  zip: string().required(inputRequired),
  vat: number().nullable(true).defined().default(null).transform(emptyToNull),
  contactPerson: string().required(inputRequired),
  datetime: string().nullable(true).defined().default(null).transform(emptyToNull),
  email: string().email().required(inputRequired),
  quarantee: bool().defined().default(null),
  invoiceNumber: string().nullable(true).defined().default(null).transform(emptyToNull),
  productProducer: string().required(inputRequired),
  productModel: string().required(inputRequired),
  productSerialNumber: string().required(inputRequired),
  productDescription: string().nullable(true).defined().default(null).transform(emptyToNull),
  serviceMileage: number().nullable(true).defined().default(null),
  serviceTimeOnRoad: number().nullable(true).defined().default(null),
  serviceDuration: number().nullable(true).defined().default(null),
  serviceTotalPrice: number().nullable(true).defined().default(null),
});

export default serviceFormSchema;
